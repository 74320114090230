import * as React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SplitSection from "../components/SplitSection"
import TextWithLogo from "../components/TextWithLogo"
import { GatsbyImage } from "gatsby-plugin-image"
import Header from "../components/header"
import gsap from "gsap"
import Footer from "../components/footer"

import Form from "../components/form"

const FormWrapper = ({ membershipFormDescription, inputs }) => {
  return (
    <div className='flex flex-c flex-s-b h-100-p over-h'>
      <div className=''>
        <h2 className='c-2A2A2A m-w-500 d-n-b'>{membershipFormDescription}</h2>
      </div>
      <Form inputs={inputs} memberships name='memberships' classEnquiryMembership='d-b-b' classEnquiry='d-n-b' linksClass='d-n-b' />
    </div>
  )
}
export default class memberships extends React.Component {
  componentDidMount() {
    let tl = gsap.timeline({ onStart: this.blackNav })
    tl.to("#nav-bar", { y: "0%", duration: 1, ease: "ease" })
  }
  blackNav() {
    const nav = document.querySelector("#nav-bar")
    nav.classList.add("black")
  }
  constructor(props) {
    super(props)
    this.state = {
      currentInput: "FULL NAME",
      inputs: [
        {
          name: "FULLNAME",
          required: true,
          type: "text",
          title: "PLEASE PROVIDE YOUR FULL NAME",
          placeholder: "YOUR NAME",
        },
        {
          name: "email",
          required: true,
          type: "text",
          title: "PLEASE PROVIDE YOUR email",
          placeholder: " YOUR EMAIL",
        },
        {
          name: "PHONENUMBER",
          required: true,
          type: "text",
          title: "PLEASE PROVIDE YOUR PHONE NUMBER",
          placeholder: " YOUR PHONE NUMBER",
        },
        {
          name: "DATEOFBIRTH",
          required: true,
          type: "text",
          title: "PLEASE PROVIDE YOUR DATE OF BIRTH ",
          placeholder: " YOUR DATE OF BIRTH ",
        },
        {
          name: "RESIDINGCITY",
          required: true,
          type: "text",
          title: "PLEASE PROVIDE YOUR RESIDING CITY ",
          placeholder: " YOUR RESIDING CITY ",
        },
        {
          name: "OCCUPATIONPROFESSION",
          required: true,
          type: "text",
          title: "PLEASE PROVIDE YOUR OCCUPATION/PROFESSION ",
          placeholder: " YOUR OCCUPATION/PROFESSION ",
        },
      ],
      currentIndex: 0,
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            datoCmsMembership {
              membershipHeading
              membershipTitle
              membershipParagraph
              membershipImage {
                gatsbyImageData
              }
              membershipFormTitle
              membershipFormDescription
              useLinkInstead
              link
            }
          }
        `}
        render={({ datoCmsMembership }) => (
          <Layout>
            <Seo title='Memberships' />
            <Header className='black w-b' isLink={datoCmsMembership.useLinkInstead} link={datoCmsMembership.link} />
            <div data-scroll-container id='scroll-container' className='data-scroll-container'>
              <div className=' back-white p-t-50'>
                <SplitSection
                  classRight='c-d-n-b '
                  wrapperClassName='stack'
                  subWrapperClassName='b-b-1-br'
                  right={
                    <div data-scroll data-scroll-call='scale' className='h-100-p' data-scroll-offset='-100%'>
                      <GatsbyImage alt='' image={datoCmsMembership.membershipImage.gatsbyImageData} className='w-100-p h-100-p ' layout='fullWidth' placeholder='dominantColor' />
                    </div>
                  }
                >
                  <div className='flex flex-c flex-s-b h-100-p '>
                    <h1 className='al-left m-b-140 heading-0'>{datoCmsMembership.membershipHeading}</h1>
                    <TextWithLogo removeLink textClassName='alt' title={datoCmsMembership.membershipTitle} text={datoCmsMembership.membershipParagraph} className='p-t-140 '></TextWithLogo>
                  </div>
                </SplitSection>
                <SplitSection right={<FormWrapper inputs={this.state.inputs} membershipFormDescription={datoCmsMembership.membershipFormDescription} />} wrapperClassName='b-t-1-b stack' alStart classRight='c-d-n-b ' subWrapperClassName='b-b-1-br'>
                  <div className='flex flex-c'>
                    <h1 className='c-2A2A2 al-left form-heading-0'>{datoCmsMembership.membershipFormTitle}</h1>
                    <h2 className='c-2A2A2A m-w-500 d-b-b display-none m-t-120 '>{datoCmsMembership.membershipFormDescription}</h2>
                  </div>
                </SplitSection>
                <Footer />
              </div>
            </div>
          </Layout>
        )}
      />
    )
  }
}
